import React, {useState} from 'react'
import {useSupabase} from '/@/context/supabaseContext'
import {
    Alert,
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    InputAdornment,
    Link as MuiLink,
    Snackbar,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import AuthHomeLink from "/@/components/onboarding/AuthHomeLink";
import StyledTextfield from "/@/components/onboarding/StyledTextfield";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {DotLottiePlayer} from "@dotlottie/react-player";
// @ts-ignore
import idleAnimation from '/@/assets/animations/idle.lottie';


export default Page;

function Page () {
    const theme = useTheme();
    const {t} = useTranslation();
    const {signIn} = useSupabase();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const handleLogin = async (event: React.FormEvent) => {
        event.preventDefault();

        setLoading(true);
        try {
            await signIn({email, password});
        } catch (error: any) {
            setErrorMessage(error?.error_description || error?.message);
        }
        setLoading(false);
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                pb: 10,
                minHeight: "100vh",
                backgroundColor: theme.palette.secondary.main,
            }}
        >
            {errorMessage && (
                <Snackbar
                    open={Boolean(errorMessage)}
                    autoHideDuration={5000}
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    onClose={() => setErrorMessage("")}
                >
                    <Alert severity="error">{errorMessage}</Alert>
                </Snackbar>
            )}
            {!isMobile && <AuthHomeLink/>}
            <Container maxWidth="sm">
                <DotLottiePlayer
                    key={'idle'}
                    src={idleAnimation}
                    autoplay
                    loop
                />
                <Grid container justifyContent={"center"} spacing={2} mt={-10}>
                    <Grid item xs={12}>
                        <Typography
                            textAlign={"center"}
                            variant="h3"
                            sx={{
                                fontWeight: 700,
                                color: theme.palette.primary.main,
                            }}
                        >
                            {t('login')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            textAlign={"center"}
                            variant="body1"
                            sx={{
                                color: theme.palette.primary.main,
                            }}
                        >
                            {t('no_account_prompt')} {" "}
                            <a href="/onboarding" style={{textDecoration: 'none'}}>
                                <MuiLink
                                    sx={{
                                        color: theme.palette.secondary.light,
                                        textDecoration: "none",
                                    }}
                                >
                                    {t('signup')}
                                </MuiLink>
                            </a>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            textAlign={"center"}
                            variant="body1"
                            sx={{
                                color: theme.palette.primary.main,
                            }}
                        >
                            <a href="/reset-password" style={{textDecoration: 'none'}}>
                                <MuiLink
                                    sx={{
                                        color: theme.palette.secondary.light,
                                        textDecoration: "none",
                                    }}
                                >
                                    {t('forgot_password')}
                                </MuiLink>
                            </a>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <form onSubmit={handleLogin}>
                            <Grid
                                container
                                item
                                xs={12}
                                mt={4}
                                spacing={2}
                                justifyContent={"center"}
                            >
                                <Grid item xs={12}>
                                    <StyledTextfield
                                        fullWidth
                                        placeholder={t('enter_email')}
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <StyledTextfield
                                        fullWidth
                                        placeholder={t('enter_password')}
                                        type={showPassword ? "text" : "password"}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={6} mt={4}>
                                    <a href="/" style={{textDecoration: 'none'}}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            sx={{
                                                bgcolor: theme.palette.primary.main,
                                                color: "#C91C1C",
                                                p: 2,
                                                borderRadius: "10px",
                                            }}
                                            fullWidth
                                        >
                                            {t('go_back')}
                                        </Button>
                                    </a>
                                </Grid>
                                <Grid item xs={6} mt={4}>
                                    <Button
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        sx={{
                                            bgcolor: theme.palette.secondary.light,
                                            p: 2,
                                            borderRadius: "10px",
                                        }}
                                        disabled={loading}
                                    >
                                        {loading ? t('loading') : t('login')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}